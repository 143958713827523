import React, { useState } from "react";
import img from "../../../../assets/logo.png";
import {
  BiHome,
  BiMenu,
  BiNews,
  BiPlanet,
  BiUser,
  BiX,
  BiChevronDown,
  BiLogOut,
} from "react-icons/bi";
import { FaBookOpen, FaHandshake } from "react-icons/fa";
import "./sidebar.css";
import { useNavigate } from "react-router-dom";

function SideBar({ user, onLogout }) {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdowns, setDropdowns] = useState({});
  const navigate = useNavigate();

  // Check if the logged-in user is an admin
  const isAdmin = user && user.role === "admin";

  // Toggle the sidebar visibility
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Toggle specific dropdown menu
  const toggleDropdown = (key) => {
    setDropdowns((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    if (onLogout) onLogout();

    navigate("/login");
  };

  return (
    <>
      <div className="menu-icon" onClick={toggleSidebar}>
        {isOpen ? (
          <BiX className="toggle-icon" />
        ) : (
          <BiMenu className="toggle-icon" />
        )}
      </div>
      <div className={`menu ${isOpen ? "open" : ""}`}>
        <div className="logo">
          <img src={img} alt="" className="logo-img" />
          <h2>UHDSS-Hospitality</h2>
        </div>

        <div className="menu-list">
          {/* Home Dropdown */}
          <div className={`item ${dropdowns.home ? "open" : ""}`}>
            <div
              onClick={() => toggleDropdown("home")}
              className="dropdown-header"
            >
              <BiHome className="icon" />
              <span>Home</span>
              <BiChevronDown
                className={`dropdown-icon ${dropdowns.home ? "open" : ""}`}
              />
            </div>
            <div className="dropdown-content">
              <a href="/admin">Overview</a>
              <a href="/admin">Updates</a>
              <a href="/admin">Statistics</a>
            </div>
          </div>

          {/* Users Dropdown (Admin Only) */}
          {isAdmin && (
            <div className={`item ${dropdowns.users ? "open" : ""}`}>
              <div
                onClick={() => toggleDropdown("users")}
                className="dropdown-header"
              >
                <BiUser className="icon" />
                <span>Users</span>
                <BiChevronDown
                  className={`dropdown-icon ${dropdowns.users ? "open" : ""}`}
                />
              </div>
              <div className="dropdown-content">
                <a href="/admin">Active Users</a>
                <a href="/admin">User Roles</a>
              </div>
            </div>
          )}

          {/* Bookings Dropdown */}
          <div className={`item ${dropdowns.bookings ? "open" : ""}`}>
            <div
              onClick={() => toggleDropdown("bookings")}
              className="dropdown-header"
            >
              <FaBookOpen className="icon" />
              <span>Bookings</span>
              <BiChevronDown
                className={`dropdown-icon ${dropdowns.bookings ? "open" : ""}`}
              />
            </div>
            <div className="dropdown-content">
              <a href="/admin/Booking">Booking History Destination</a>
              <a href="/admin/BookingApartment">Booking History Apartment</a>
            </div>
          </div>

          {/* Destination Dropdown */}
          <div className={`item ${dropdowns.destination ? "open" : ""}`}>
            <div
              onClick={() => toggleDropdown("destination")}
              className="dropdown-header"
            >
              <BiPlanet className="icon" />
              <span>Destination</span>
              <BiChevronDown
                className={`dropdown-icon ${
                  dropdowns.destination ? "open" : ""
                }`}
              />
            </div>
            <div className="dropdown-content">
              <a href="/Admin/DestinationView"> Add Destination</a>
              <a href="/admin">All Destinations</a>
              {isAdmin && <a href="/admin">All Destinations</a>}
            </div>
          </div>

          {/* Blog Dropdown */}
          <div className={`item ${dropdowns.blog ? "open" : ""}`}>
            <div
              onClick={() => toggleDropdown("blog")}
              className="dropdown-header"
            >
              <BiNews className="icon" />
              <span>Blog</span>
              <BiChevronDown
                className={`dropdown-icon ${dropdowns.blog ? "open" : ""}`}
              />
            </div>
            <div className="dropdown-content">
              <a href="/Admin/AddBlog">Add blogs</a>
              <a href="/admin">All Blogs</a>
              {isAdmin && <a href="/admin">Create Post</a>}
            </div>
          </div>

          {/* Offers Dropdown */}
          <div className={`item ${dropdowns.offers ? "open" : ""}`}>
            <div
              onClick={() => toggleDropdown("offers")}
              className="dropdown-header"
            >
              <FaHandshake className="icon" />
              <span>Offers</span>
              <BiChevronDown
                className={`dropdown-icon ${dropdowns.offers ? "open" : ""}`}
              />
            </div>
            <div className="dropdown-content">
              {isAdmin && <a href="/admin">All Offers</a>}
              <a href="/Admin/OfferView">Add Offers</a>
              <a href="/admin">all Offers</a>
            </div>
          </div>
        </div>

        {/* Logout Button */}
        <div className="logout-button">
          <button onClick={handleLogout} className="logout-btn">
            <BiLogOut className="icon" /> Logout
          </button>
        </div>
      </div>
    </>
  );
}

export default SideBar;
