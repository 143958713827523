import React, { useState, useEffect } from "react";
import "./destinationview.css";
import SideBar from "../Dashboard/components/NavBar/SideBar";
import axios from "axios";

const Destination = () => {
  const [formData, setFormData] = useState({
    image: null,
    location: "",
    price: "",
    destination: "",
    title: "",
    description: "",
    CostPerPerson: "",
    gallery: []
  });

  const [destinations, setDestinations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editingDestination, setEditingDestination] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Fetch destinations
  const fetchDestinations = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get("https://uhospitality-backend-production.up.railway.app/api/destination");
      setDestinations(response.data);
    } catch (error) {
      console.error("Error fetching destinations:", error);
      alert(`Failed to fetch destinations: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDestinations();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const { name, files } = e.target;
    if (name === "image") {
      setFormData((prevState) => ({
        ...prevState,
        image: files[0],
      }));
    } else if (name === "gallery") {
      setFormData((prevState) => ({
        ...prevState,
        gallery: Array.from(files),
      }));
    }
  };

  const handleEdit = (destination) => {
    setEditingDestination(destination);
    setFormData({
      destination: destination.destination,
      location: destination.location,
      price: destination.price,
      title: destination.title,
      description: destination.description,
      CostPerPerson: destination.costPerPerson,
      image: null,
      gallery: []
    });
    setIsPopupOpen(true);
  };

  const handleDelete = async (destinationId) => {
    if (!window.confirm("Are you sure you want to delete this destination?")) {
      return;
    }

    try {
      setIsLoading(true);
      await axios.delete(
        `https://uhospitality-backend-production.up.railway.app/api/deleteDestination/${destinationId}`
      );
      await fetchDestinations();
      alert("Destination deleted successfully");
    } catch (error) {
      console.error("Error deleting destination:", error);
      alert(`Failed to delete destination: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data
    if (!formData.image && !editingDestination) {
      alert("Please upload an image");
      return;
    }

    const price = parseFloat(formData.price);
    const costPerPerson = parseFloat(formData.CostPerPerson);
    if (isNaN(price) || price <= 0 || isNaN(costPerPerson) || costPerPerson <= 0) {
      alert("Please enter valid price and cost per person");
      return;
    }

    try {
      setIsLoading(true);

      // Prepare form data
      const data = new FormData();
      data.append("destination", formData.destination.trim());
      data.append("location", formData.location.trim());
      data.append("price", price);
      data.append("title", formData.title.trim());
      data.append("description", formData.description.trim());
      data.append("CostPerPerson", costPerPerson);

      // Only append image if a new one is selected when editing
      // or if it's a new destination
      if (formData.image) {
        data.append("image", formData.image);
      }

      // Only append gallery images if new ones are selected
      if (formData.gallery && formData.gallery.length > 0) {
        formData.gallery.forEach((file) => {
          data.append("gallary", file);
        });
      }

      let response;
      if (editingDestination) {
        // Update existing destination
        response = await axios.patch(
          `https://uhospitality-backend-production.up.railway.app/api/updateDestination/${editingDestination._id}`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        // Create new destination
        response = await axios.post(
          "https://uhospitality-backend-production.up.railway.app/api/destination",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      // Refresh destinations list
      await fetchDestinations();

      // Reset form and editing state
      setFormData({
        image: null,
        location: "",
        price: "",
        destination: "",
        title: "",
        description: "",
        CostPerPerson: "",
        gallery: []
      });
      setEditingDestination(null);
      setIsPopupOpen(false);

      alert(response.data.message || (editingDestination ? "Destination updated successfully!" : "Destination added successfully!"));
    } catch (error) {
      console.error("Error saving destination:", error);
      alert(`Failed to save destination: ${error.response?.data?.message || error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setEditingDestination(null);
    setFormData({
      image: null,
      location: "",
      price: "",
      destination: "",
      title: "",
      description: "",
      CostPerPerson: "",
      gallery: []
    });
    setIsPopupOpen(false);
  };

  return (
    <div className="sidebar">
      <SideBar />

      <div className="dest-container">
        <h1 className="dest-title">Destination Management</h1>

        <button onClick={() => setIsPopupOpen(true)} className="add-destination-button">
          Add New Destination
        </button>

        {isPopupOpen && (
          <div className="popup-overlay">
            <div className="popup-content">
              <h2 className="dest-title">
                {editingDestination ? "Edit Destination" : "Add New Destination"}
              </h2>
              <form onSubmit={handleSubmit} className="dest-form">
                <div className="form-group">
                  <label className="form-label">Destination</label>
                  <input
                    type="text"
                    name="destination"
                    value={formData.destination}
                    onChange={handleInputChange}
                    placeholder="Enter destination name"
                    className="form-input"
                    required
                    maxLength={100}
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Location</label>
                  <input
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    placeholder="Enter location"
                    className="form-input"
                    required
                    maxLength={100}
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Price</label>
                  <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleInputChange}
                    placeholder="Enter price"
                    className="form-input"
                    required
                    min="0"
                    step="0.01"
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    placeholder="Enter Title"
                    className="form-input"
                    required
                    maxLength={100}
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Description</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Enter Description"
                    className="form-input"
                    required
                    maxLength={500}
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Cost Per Person</label>
                  <input
                    type="number"
                    name="CostPerPerson"
                    value={formData.CostPerPerson}
                    onChange={handleInputChange}
                    placeholder="Enter Cost per person"
                    className="form-input"
                    required
                    min="0"
                    step="0.01"
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Upload Image</label>
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="form-input-file"
                    required={!editingDestination}
                  />
                  {formData.image && (
                    <img
                      src={URL.createObjectURL(formData.image)}
                      alt="Uploaded"
                      className="uploaded-image"
                    />
                  )}
                </div>

                <div className="form-group">
                  <label className="form-label">Upload Gallery</label>
                  <input
                    type="file"
                    name="gallery"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="form-input-file"
                    multiple
                  />
                  {formData.gallery.length > 0 && (
                    <div className="gallery-preview">
                      {formData.gallery.map((file, index) => (
                        <img
                          key={index}
                          src={URL.createObjectURL(file)}
                          alt={`Gallery ${index + 1}`}
                          className="gallery-image"
                        />
                      ))}
                    </div>
                  )}
                </div>

                <div className="form-actions">
                  <button
                    type="button"
                    className="cancel-button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="submit-button"
                    disabled={isLoading}
                  >
                    {isLoading
                      ? editingDestination
                        ? "Updating..."
                        : "Adding..."
                      : editingDestination
                      ? "Update"
                      : "Add Destination"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        <div className="dest-table-wrapper">
          <h2 className="dest-table-title">Destinations</h2>
          {isLoading ? (
            <p>Loading destinations...</p>
          ) : destinations && destinations.length > 0 ? (
            <table className="dest-table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Destination</th>
                  <th>Location</th>
                  <th>Price</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {destinations.map((destination) => (
                  <tr key={destination._id}>
                    <td>
                      {destination.image && (
                        <img
                          src={destination.image}
                          alt={destination.title}
                          className="dest-table-image"
                        />
                      )}
                    </td>
                    <td>{destination.title}</td>
                    <td>{destination.destination}</td>
                    <td>{destination.location}</td>
                    <td>${destination.price.toFixed(2)}</td>
                    <td className="actions-btn">
                      <button
                        onClick={() => handleEdit(destination)}
                        className="action-button edit"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(destination._id)}
                        className="action-button delete"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="no-destinations">
              <p>No destinations found.</p>
              <p>Add a new destination to get started!</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Destination;

