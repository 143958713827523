import React, { useEffect } from 'react'
import './footer.css'
import { IoIosPlanet } from "react-icons/io";
import { ImFacebook } from "react-icons/im";
import { FaInstagram } from 'react-icons/fa6';
import { FaWhatsapp } from 'react-icons/fa';
import { BsTwitterX } from 'react-icons/bs';
import Aos from 'aos';
import 'aos/dist/aos.css';


const Footer = () => {

  
  useEffect(()=>{
    Aos.init({duration: 2000})
  },[])
  return (
    <section className='footer section'>
      <div className="secContainer container grid">
        <div className="footerDiv">
          <div data-aos = 'fade-up' className="footerLogo">
            <a href="/" className="logo flex">
              <h1 className='flex'><IoIosPlanet className='icon' />
              UHDSS-Hospitality
              </h1>
            </a>
          </div>
          <div data-aos = 'fade-up' data-aos-duration = '2500' className="socials flex">
        <ImFacebook className='icon' />
        <FaInstagram className='icon' />
        <FaWhatsapp className='icon' />
        <BsTwitterX className='icon' />
        </div>
        </div>
       <div data-aos = 'fade-up' data-aos-duration = '4500' className="footerLinks">
        <span className='linkTitle'>
          Helpful Links
        </span>
        <li>
          <a href="/">Destination</a>
        </li><li>
          <a href="/">Support</a>
        </li>
        <li>
          <a href="/">Travel & Conditions</a>
        </li>
        <li>
          <a href="/">Privacy</a>
        </li>
       </div>
       <div data-aos = 'fade-up' data-aos-duration = '5500' className="footerLinks">
        <span className='linkTitle'>
          Information
        </span>
        <li>
          <a href="/">Home</a>
        </li><li>
          <a href="/">Explore</a>
        </li>
        <li>
          <a href="/">Offers</a>
        </li>
        <li>
          <a href="/">Blog</a>
        </li>
       </div>
       <div data-aos = 'fade-right' data-aos-duration = '6500' className="footerLinks">
        <span className='linkTitle'>
          Contact Us
        </span>
        <span className='phone'>+250 785 395 473</span>
        <span className='email'>travel@uhdss.com</span>
        
       </div>
      </div>
    </section>
  )
}

export default Footer