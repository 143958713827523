import React from "react";
import "./Partners.css";

const partnersData = [
  { logo: "https://logo.clearbit.com/apple.com" },
  { logo: "https://logo.clearbit.com/google.com" },
  { logo: "https://logo.clearbit.com/microsoft.com" },
  { logo: "https://logo.clearbit.com/amazon.com" },
  { logo: "https://logo.clearbit.com/facebook.com" },
];

const Partners = ({ partners = partnersData }) => {
  return (
    <div className="partners-container">
      <h2>Our Partners</h2>
      <div className="partners-list">
        {partners.map((partner, index) => (
          <div key={index} className="partner-card">
            <img
              src={partner.logo}
              alt={`${partner.name} logo`}
              className="partner-logo"
            />
            <h3 className="partner-name">{partner.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

const App = () => {
  return (
    <div className="App">
      <Partners />
    </div>
  );
};

export default App;
