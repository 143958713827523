import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './blog.css';
import { BsArrowRightShort } from "react-icons/bs";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    Aos.init({ duration: 2000 });

    const fetchBlogs = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`https://uhospitality-backend-production.up.railway.app/api/Blog?page=${currentPage}`);

        console.log("blogs", response.data);
        setBlogs(response.data.data);
        setTotalPages(response.data.totalPages || 1);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching blogs:", err);
        setError("Failed to load blogs. Please try again later.");
        setIsLoading(false);
      }
    };

    fetchBlogs();
  }, [currentPage]);

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  if (isLoading) {
    return (
      <section id='blog' className='blog section container'>
        <div className="loading">Loading blogs...</div>
      </section>
    );
  }

  if (error) {
    return (
      <section id='blog' className='blog section container'>
        <div className="error">{error}</div>
      </section>
    );
  }

  return (
    <section id='blog' className='blog section container'>
      <div className="secContainer grid">
        <div className="secIntro">
          <h2 data-aos='fade-up' data-aos-duration='2500' className='secTitle'>Our Best Blogs</h2>
          <p data-aos='fade-up' data-aos-duration='2500'>
            An insight to the incredible experience in the world.
          </p>
        </div>

        <div className="mainContainer grid">
          {blogs.length > 0 ? (
            blogs.map((blog) => (
              <div key={blog._id} data-aos='fade-up' className="singlePost grid">
                <div className="imgDiv">
                  <img
                    src={blog.Image || 'https://via.placeholder.com/300x200'}
                    alt={blog.Title}
                  />
                </div>
                <div className="postDetail">
                  <h3>{blog.Title}</h3>
                  <p>{blog.Content.slice(0, 80)}...</p>
                </div>
                <Link to={`/BlogDetails/${blog._id}`} className='flex'>
                  Read More
                  <BsArrowRightShort className='icon' />
                </Link>
              </div>
            ))
          ) : (
            <p>No blogs available at the moment.</p>
          )}
        </div>

        <div className="pagination">
          <button
            onClick={handlePrevious}
            disabled={currentPage === 1}
            className="paginationBtn"
          >
            Previous
          </button>
          <span className="paginationInfo">
            {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className="paginationBtn"
          >
            Next
          </button>
        </div>
      </div>
    </section>
  );
};

export default Blog;
