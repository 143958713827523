import React from 'react'
import SideBar from '../NavBar/SideBar'
import './layout.css'

function Layout() {
  return (
    <>
    <div className='dashboard'>
      <SideBar />
      <div className="dashboard-content">
        {/* <Content /> */}
        {/* <Profile /> */}
      </div>
    </div>
    
    </>
  )
}

export default Layout