import React, { useEffect, useState } from "react";
import axios from "axios";
import "./popular.css";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const Popular = () => {
  const [destinations, setDestinations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init({ duration: 2000 });

    const fetchDestinations = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get('https://uhospitality-backend-production.up.railway.app/api/destination');
        setDestinations(response.data);
      
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching destinations:", err);
        setError("Failed to load destinations. Please try again later.");
        setIsLoading(false);
      }
    };

    fetchDestinations();
  }, [currentPage]);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handleDestinationClick = (destinationId) => {
    navigate(`/TourPage/${destinationId}`);
  };

  if (isLoading) {
    return <div className="loading">Loading destinations...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <section id="popular" className="popular section container">
      <div className="secContainer">
        <div className="secHeader flex">
          <div data-aos="fade-right" data-aos-duration="2500" className="textDiv">
            <h2 className="secTitle">Popular Destinations</h2>
            <p>From historical cities to natural spectacles, come see the best of the world!</p>
          </div>
          <div data-aos="fade-left" data-aos-duration="2500" className="iconsDiv flex">
            <BsArrowLeftShort 
              className="icon leftIcon" 
              onClick={handlePrevPage} 
              style={{ cursor: currentPage > 1 ? 'pointer' : 'not-allowed' }}
            />
            <span>Page {currentPage} of {totalPages}</span>
            <BsArrowRightShort 
              className="icon rightIcon" 
              onClick={handleNextPage} 
              style={{ cursor: currentPage < totalPages ? 'pointer' : 'not-allowed' }}
            />
          </div>
        </div>
        
        <div className="mainContent grid">
          {destinations.slice((currentPage - 1) * 6, currentPage * 6).map((destination) => (
            <div 
              key={destination._id} 
              data-aos="fade-up" 
              className="singleDestination"
              onClick={() => handleDestinationClick(destination._id)}
            >
              <div className="destinationImage">
              <img 
  src={destination.image || '/placeholder.svg?height=200&width=300'} 
  alt={destination.name || 'Destination Image'} 
/>

                <div className="overlayInfo">
                  <h3>{destination.destination}</h3>
                  <BsArrowRightShort className="icon" />
                </div>
              </div>
              
              <div className="destFooter">
                <div className="destText flex">
                  <h6>{destination.location}</h6>
                  <span className="flex">
                    <span className="dot">
                      $ {destination.price}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Popular;

