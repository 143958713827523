import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './OfferDetails.css';
import Navbar from "./../../NavBar/Navbar";
import Footer from "../../Footer/Footer";
import axios from 'axios';

const OfferDetails = () => {
  const { id } = useParams();
  const [offerData, setOfferData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [maxGuestLimit, setMaxGuestLimit] = useState(null);
  const [totalCost, setTotalCost] = useState(0);
  const [costPerPerson, setCostPerPerson] = useState(0);
  const [pricePerNight, setPricePerNight] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    checkIn: '',
    checkOut: '',
    guests: 1,
    offerId: ''
  });
  const [guestError, setGuestError] = useState('');

  // Fetch guest limit
  useEffect(() => {
    const fetchGuestLimit = async () => {
      try {
        const response = await axios.get("https://uhospitality-backend-production.up.railway.app/api/limit");
        const limit = response.data.limit || response.data;
        const maxGuests = limit.maxGuestLimit;

        if (typeof maxGuests === 'number' && maxGuests > 0) {
          setMaxGuestLimit(maxGuests);
        } else {
          setMaxGuestLimit(4);
        }
      } catch (err) {
        console.error('Error fetching guest limit:', err);
        setMaxGuestLimit(4);
      }
    };

    fetchGuestLimit();
  }, []);

  // Fetch offer data
  useEffect(() => {
    const fetchOfferData = async () => {
      try {
        const response = await axios.get(`https://uhospitality-backend-production.up.railway.app/api/offer/${id}`);
        setOfferData(response.data.data);
        setFormData(prev => ({
          ...prev,
          offerId: id
        }));
      } catch (err) {
        console.error('Error fetching offer data:', err);
        setError('Failed to load offer data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchOfferData();
  }, [id]);

  // Calculate costs when dates, guests, or offer data change
  useEffect(() => {
    if (offerData && formData.checkIn && formData.checkOut) {
      // Calculate total price per night based on number of guests
      const basePrice = offerData.Price;
      const guestCount = formData.guests;
      
      // Calculate price per night including all guests
      // You can adjust this formula based on your pricing strategy
      const calculatedPricePerNight = basePrice * guestCount;
      setPricePerNight(calculatedPricePerNight);

      const checkInDate = new Date(formData.checkIn);
      const checkOutDate = new Date(formData.checkOut);
      const numberOfNights = Math.ceil((checkOutDate - checkInDate) / (1000 * 60 * 60 * 24));
      
      if (numberOfNights > 0) {
        const totalCostCalculated = calculatedPricePerNight * numberOfNights;
        setTotalCost(totalCostCalculated);
        setCostPerPerson(totalCostCalculated / guestCount);
      }
    } else if (offerData) {
      // Set initial price per night when offer data is loaded
      setPricePerNight(offerData.Price * formData.guests);
    }
  }, [formData.checkIn, formData.checkOut, formData.guests, offerData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'guests') {
      const guestCount = parseInt(value) || 1;
      if (maxGuestLimit !== null && guestCount > maxGuestLimit) {
        setGuestError(`Maximum ${maxGuestLimit} guests allowed`);
      } else {
        setGuestError('');
        setFormData(prev => ({
          ...prev,
          guests: guestCount
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.guests > maxGuestLimit) {
      setGuestError(`Number of guests cannot exceed ${maxGuestLimit}`);
      return;
    }

    try {
      const bookingData = {
        ...formData,
        pricePerNight,
        totalCost,
        costPerPerson
      };

      const response = await axios.post(
        'https://uhospitality-backend-production.up.railway.app/api/bookingapartment',
        bookingData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data) {
        alert('Booking submitted successfully!');
        setFormData({
          name: '',
          email: '',
          checkIn: '',
          checkOut: '',
          guests: 1,
          offerId: id
        });
      }
    } catch (error) {
      console.error('Error submitting booking:', error);
      alert(error.response?.data?.message || 'Failed to submit booking. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!offerData) return <div>No offer data found.</div>;

  return (
    <>
      <Navbar />
      <div className="offer-details">
        <div className="offer-images">
          {offerData.gallary && offerData.gallary.map((image, index) => (
            <img key={index} src={image} alt={`Room view ${index + 1}`} className="room-image" />
          ))}
        </div>
        <div className="offer-content">
          <h1>{offerData.title}</h1>
          <p className="offer-description">{offerData.description}</p>
          <div className="price-info">
            <div className="base-price">
              <span className="price">${pricePerNight.toFixed(2)}</span> per night for {formData.guests} guest{formData.guests > 1 ? 's' : ''}
              <p className="base-note">Base price: ${offerData.Price} per person per night</p>
            </div>
            {totalCost > 0 && (
              <div className="dynamic-pricing">
                <p>Total Cost: ${totalCost.toFixed(2)}</p>
                <p>Cost per Person: ${costPerPerson.toFixed(2)}</p>
              </div>
            )}
          </div>
          <div className="amenities">
            <h2>Features</h2>
            <ul>
              {offerData.features && offerData.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
          <div className="booking-form">
            <h2>Book Your Stay</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="name">Full Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="checkIn">Check-in:</label>
                  <input
                    type="date"
                    id="checkIn"
                    name="checkIn"
                    value={formData.checkIn}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="checkOut">Check-out:</label>
                  <input
                    type="date"
                    id="checkOut"
                    name="checkOut"
                    value={formData.checkOut}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="guests">
                    Guests (Maximum: {maxGuestLimit}):
                  </label>
                  <input
                    type="number"
                    id="guests"
                    name="guests"
                    max={maxGuestLimit}
                    value={formData.guests}
                    onChange={handleInputChange}
                    required
                  />
                  {guestError && (
                    <p style={{ color: 'red', fontSize: '0.875rem', marginTop: '0.25rem' }}>
                      {guestError}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="submit-btn"
                    disabled={!!guestError || formData.guests > maxGuestLimit}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OfferDetails;