import React, { useState, useEffect } from "react";
import "./Apartment.css";
import SideBar from "../NavBar/SideBar";
import axios from "axios";
import { Loader2 } from "lucide-react";

const BookingApartment = () => {
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [limit, setLimit] = useState({ maxGuestLimit: "" });
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // Fetch both bookings and limit when component mounts
  useEffect(() => {
    const initializeData = async () => {
      await Promise.all([fetchBookings(), fetchLimit()]);
    };
    initializeData();
  }, []);

  const fetchBookings = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        "https://uhospitality-backend-production.up.railway.app/api/bookingapartment"
      );
      setBookings(response.data.data);
    } catch (error) {
      console.error("Error fetching bookings:", error);
      setError("Failed to fetch bookings. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLimit = async () => {
    try {
      // First try to get from localStorage
      const storedLimit = localStorage.getItem('guestLimit');
      if (storedLimit) {
        setLimit({ maxGuestLimit: JSON.parse(storedLimit) });
      }

      // Then fetch from API and update if different
      const response = await axios.get("https://uhospitality-backend-production.up.railway.app/api/limit");
      if (response.data && response.data.maxGuestLimit !== undefined) {
        setLimit(response.data);
        // Update localStorage with latest value from server
        localStorage.setItem('guestLimit', JSON.stringify(response.data.maxGuestLimit));
      }
    } catch (error) {
      console.error("Error fetching limit:", error);
      // If API fails, at least we still have localStorage value if it exists
      const storedLimit = localStorage.getItem('guestLimit');
      if (storedLimit) {
        setLimit({ maxGuestLimit: JSON.parse(storedLimit) });
      }
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this booking?")) {
      try {
        await axios.delete(
          `https://uhospitality-backend-production.up.railway.app/api/bookingapartment/${id}`
        );
        await fetchBookings();
        alert("Booking deleted successfully");
      } catch (error) {
        console.error("Error deleting booking:", error);
        setError("Failed to delete booking. Please try again.");
      }
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const newLimit = parseInt(limit.maxGuestLimit);

    try {
      // Update API
      await axios.post("https://uhospitality-backend-production.up.railway.app/api/limit", {
        maxGuestLimit: newLimit
      });

      // Update local state
      setLimit({ maxGuestLimit: newLimit });

      // Update localStorage
      localStorage.setItem('guestLimit', JSON.stringify(newLimit));

      alert(
        isEditing
          ? "Guest limit updated successfully!"
          : "Guest limit set successfully!"
      );
      setIsFormOpen(false);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating limit:", error);
      // Even if API fails, update localStorage and local state
      setLimit({ maxGuestLimit: newLimit });
      localStorage.setItem('guestLimit', JSON.stringify(newLimit));
      alert("Warning: Limit saved locally but server update failed. It will still be visible but may not sync across devices.");
    }
  };

  return (
    <div className="dashboard-layout">
      <SideBar />
      <div className="main-content">
        <div className="content-container">
          <h1 className="main-title">Booking Management</h1>

          <div className="limit-controls">
            <div className="limit-card">
              <p>Guest Limit: {limit.maxGuestLimit || "Not set"}</p>
              <button
                onClick={() => {
                  setIsFormOpen(true);
                  setIsEditing(limit.maxGuestLimit ? true : false);
                }}
                className="btn btn-outline"
              >
                {limit.maxGuestLimit ? "Edit" : "Set Limit"}
              </button>
            </div>
          </div>

          {isFormOpen && (
            <div className="modal-overlay">
              <div className="modal-content">
                <h2 className="modal-title">
                  {isEditing ? "Edit Guest Limit" : "Set Guest Limit"}
                </h2>
                <form onSubmit={handleFormSubmit} className="limit-form">
                  <div className="form-group">
                    <label htmlFor="guestLimit">Max Guest Limit</label>
                    <input
                      id="guestLimit"
                      type="number"
                      value={limit.maxGuestLimit}
                      onChange={(e) =>
                        setLimit({ maxGuestLimit: e.target.value })
                      }
                      required
                      className="form-input"
                    />
                  </div>
                  <div className="form-actions">
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={() => {
                        setIsFormOpen(false);
                        setIsEditing(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      {isEditing ? "Update" : "Save"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {error && <div className="error-message">{error}</div>}

          {isLoading ? (
            <div className="loading-container">
              <Loader2 className="loading-spinner" />
            </div>
          ) : bookings.length > 0 ? (
            <div className="card">
              <div className="card-header">
                <h2 className="card-title">Bookings</h2>
              </div>
              <div className="card-content">
                <div className="table-container">
                  <table className="data-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Check-In</th>
                        <th>Check-Out</th>
                        <th>Guests</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookings.map((booking, index) => (
                        <tr key={booking._id || index}>
                          <td>{booking.name}</td>
                          <td>{booking.email}</td>
                          <td>
                            {new Date(booking.checkIn).toLocaleDateString()}
                          </td>
                          <td>
                            {new Date(booking.checkOut).toLocaleDateString()}
                          </td>
                          <td>{booking.guests}</td>
                          <td>
                            <button
                              onClick={() => handleDelete(booking._id)}
                              className="btn btn-danger"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="empty-message">No bookings found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingApartment;