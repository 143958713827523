import React, { useState } from 'react';
import { MdEmail } from 'react-icons/md';
import { RiLockPasswordFill } from 'react-icons/ri';
import { useNavigate, useLocation } from 'react-router-dom';
import bcrypt from 'bcryptjs';
import "./loginSignUp.css";

const LoginSignUp = ({ onSubmit }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (email && password) {
        const response = await fetch('https://uhospitality-backend-production.up.railway.app/auth/register', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();

        if (response.ok) {
          const user = data.users.find(user => user.email === email);
          if (user) {
            const isPasswordValid = await bcrypt.compare(password, user.password);
            if (isPasswordValid) {
              // Generate a simple token (in real app, this should come from the server)
              const token = btoa(user.email + ':' + new Date().getTime());
              
              // Store auth data in localStorage
              localStorage.setItem('token', token);
              localStorage.setItem('user', JSON.stringify({
                id: user.id,
                email: user.email,
                role: user.role,
                name: user.name
              }));

              // Redirect based on user role
              if (user.role === 'admin') {
                navigate('/admin');
              } else if (user.role === 'client') {
                navigate('/client');
              } else {
                // Default redirect for other roles
                const from = location.state?.from || '/';
                navigate(from, { replace: true });
              }
            } else {
              setMessage('Invalid email or password');
            }
          } else {
            setMessage('Invalid email or password');
          }
        } else {
          setMessage(data.message || 'Error during login. Please try again.');
        }
      } else {
        setMessage('Please fill in all fields');
      }
    } catch (error) {
      console.error('Login error:', error);
      setMessage('Error during login. Please try again.');
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    // Add your forgot password logic here
    navigate('/forgot-password');
  };

  return (
    <div className="containerLogin">
      <div className="header">
        <div className="text">Login</div>
        <div className="underline"></div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="inputs">
          <div className="input">
            <MdEmail className="icon" />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="input">
            <RiLockPasswordFill className="icon" />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>

        <div className="forget-password">
          Forgot password?
          <span onClick={handleForgotPassword}>Click here</span>
        </div>

        <div className="submit-container">
          <button type="submit" className="submit gray">
            Login
          </button>
        </div>

        {message && <div className="message">{message}</div>}
      </form>
    </div>
  );
};

export default LoginSignUp;