import React, { useState, useEffect } from "react"
import "./home.css"
import Aos from "aos"
import "aos/dist/aos.css"

function Home() {
  const [searchText, setSearchText] = useState("")
  const [searchPrice, setSearchPrice] = useState("")
  const [searchHistory, setSearchHistory] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [searchFeedback, setSearchFeedback] = useState(null)

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  const handleSearch = () => {
    // Reset previous highlights
    const body = document.body
    const elements = body.getElementsByClassName("highlight")
    while (elements.length > 0) {
      const parent = elements[0].parentNode
      parent.replaceChild(document.createTextNode(elements[0].textContent), elements[0])
    }

    // Initialize match counter
    let matchCount = 0

    // Search and highlight text if search text is provided
    if (searchText) {
      const searchInNode = (node) => {
        if (node.nodeType === 3) {
          const text = node.nodeValue
          const regex = new RegExp(searchText, "gi")
          if (regex.test(text)) {
            matchCount++
            const span = document.createElement("span")
            span.className = "highlight"
            span.innerHTML = text.replace(regex, (match) => `<span class="highlight">${match}</span>`)
            node.parentNode.replaceChild(span, node)
          }
        } else if (node.nodeType === 1) {
          node.childNodes.forEach(searchInNode)
        }
      }

      searchInNode(body)
    }

    // Prepare feedback message
    let feedbackMessage = ""
    if (!searchText && !searchPrice) {
      feedbackMessage = "Please enter a destination or price to search."
    } else if (matchCount === 0) {
      feedbackMessage = `No destinations found matching "${searchText}"${searchPrice ? ` and price range ${searchPrice}` : ""}.`
    } else {
      feedbackMessage = `Found ${matchCount} matches for "${searchText}"${searchPrice ? ` within price range ${searchPrice}` : ""}.`
    }

    // Add to search history with feedback
    const newSearch = {
      text: searchText,
      price: searchPrice,
      timestamp: new Date().toLocaleTimeString(),
      feedback: feedbackMessage,
      success: matchCount > 0,
    }

    setSearchHistory((prev) => [newSearch, ...prev])
    setSearchFeedback(feedbackMessage)
    setShowPopup(true)
  }

  const clearHistory = () => {
    setSearchHistory([])
    setShowPopup(false)
  }

  return (
    <section className="home">
      <div className="secContainer container">
        <div className="homeText">
          <h1 data-aos="fade-up" className="title">
            Plan Your Trip With UHDSS-Hospitality
          </h1>
          <p data-aos="fade-up" data-aos-duration="2500" className="subTitle">
            Travel to your favorite city with respectful of the environment
          </p>

          <button data-aos="fade-up" data-aos-duration="3500" className="btn">
            <a href="/">Explore Now</a>
          </button>
        </div>

        <div className="homeCard">
          <div className="searchInputs">
            <div data-aos="fade-right" data-aos-duration="2000" className="inputGroup locationDivision">
              <label htmlFor="location">Location</label>
              <input
                type="text"
                id="location"
                placeholder="Dream Destination"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>

            <div data-aos="fade-right" data-aos-duration="3000" className="inputGroup priceDiv">
              <label htmlFor="price">Price</label>
              <input
                type="text"
                id="price"
                placeholder="$140-$500"
                value={searchPrice}
                onChange={(e) => setSearchPrice(e.target.value)}
              />
            </div>
          </div>

          <button data-aos="fade-left" data-aos-duration="3500" className="btn searchBtn" onClick={handleSearch}>
            Search
          </button>
        </div>

        {/* Search Feedback Popup */}
        {showPopup && (
          <div className="search-popup">
            <div className="search-popup-content">
              <div className="search-popup-header">
                <h3>Search Results</h3>
                <button className="close-popup" onClick={() => setShowPopup(false)}>
                  ×
                </button>
              </div>

              {/* Current Search Feedback */}
              {searchFeedback && (
                <div className={`search-feedback ${searchHistory[0]?.success ? "success" : "error"}`}>
                  {searchFeedback}
                </div>
              )}

              {/* Search History */}
              <div className="search-history">
                <h4>Recent Searches</h4>
                {searchHistory.map((search, index) => (
                  <div key={index} className={`search-item ${search.success ? "success" : "error"}`}>
                    <span className="search-text">
                      {search.text ? `Location: ${search.text}` : ""}
                      {search.price ? ` | Price: ${search.price}` : ""}
                    </span>
                    <span className="search-time">{search.timestamp}</span>
                    <span className="search-feedback">{search.feedback}</span>
                  </div>
                ))}
              </div>

              {searchHistory.length > 0 && (
                <button className="clear-history" onClick={clearHistory}>
                  Clear History
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default Home

