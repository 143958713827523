import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import "./BlogDetails.css";
import Footer from "../../Footer/Footer";
import axios from 'axios';
import Navbar from "../../NavBar/Navbar";
const BlogDetails = () => {
  const [blogPost, setBlogPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {

        const response = await axios.get(`https://uhospitality-backend-production.up.railway.app/api/Blog/${id}`);
        console.log("Fetched blog post data:", response.data.data); // Log the fetched data

        setBlogPost(response.data.data); // Set the state with the fetched data
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching blog post:", error); // Log any errors
        setError("Failed to load blog post. Please try again later.");
        setIsLoading(false);
      }
    };

    fetchBlogPost();
    Aos.init({ duration: 2000 });
  }, [id]);

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!blogPost) {
    return <div className="error">Blog post not found.</div>;
  }

  return (
    <>
      <Navbar />
      <div className="blog-details-container">

        <div className="blog-details-content">
          <div className="blog-title-section">
            <h1 className="blog-title">{blogPost.title}</h1>
            <h2 className="blog-subtitle">{blogPost.subtitle}</h2>
            <h3 className="blog-subsubtitle">{blogPost.subsubtitle}</h3>
          </div>

          <div className="blog-image-gallery">
            {blogPost.gallary && blogPost.gallary.map((image, index) => (
              <div
                key={index}
                className="blog-gallery-image"
                data-aos="fade-up"
                data-aos-delay={index * 200}
              >
                <img
                  src={image}
                  alt={`${blogPost.title} - ${index + 1}`}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "https://via.placeholder.com/800x500?text=Image+Not+Found";
                  }}
                />

              </div>
            ))}
          </div>

          <div className="blog-text-content" data-aos="fade-up">
            <div className="blog-description">
              {blogPost.description && blogPost.description.split("\n\n").map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>

            <div className="blog-highlights">
              <h3>Destination Highlights</h3>
              <ul>
                {blogPost.highlights && blogPost.highlights.map((highlight, index) => (
                  <li key={index}>{highlight}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BlogDetails;
