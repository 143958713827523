import React from 'react'
import Navbar from '../NavBar/Navbar'
import Home from '../Home/Home'
import Footer from '../Footer/Footer'
import Popular from '../Popular/Popular'
import Offers from '../Offers/Offers'
import About from '../About/About'
import Blog from '../Blog/Blog'
import Partners from '../Partners/Partners.js'
function WebPages() {
  return (
    <div>
        <Navbar />
        {/* Add IDs to the main sections */}
        <section id="home">
          <Home />
        </section>
        <section id="popular">
          <Popular />
        </section>
        <section id="offers">
          <Offers />
        </section>
        <section id="about">
          <About />
        </section>
        <section id="blog">
          <Blog />
        </section>
        <section id="Partners">
          <Partners/>
        </section>
        <Footer />
    </div>
  )
}

export default WebPages
