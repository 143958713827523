import React, { useState, useEffect } from 'react'
import './Booking.css'
import SideBar from '../NavBar/SideBar'
import axios from 'axios'

const Booking = () => {
  const [bookings, setBookings] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchBookings()
  }, [])

  const fetchBookings = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const response = await axios.get('https://uhospitality-backend-production.up.railway.app/api/booking')
      console.log('Fetched data:', response.data.bookings)
      setBookings(response.data.bookings)
    } catch (error) {
      console.error('Error fetching bookings:', error)
      setError('Failed to fetch bookings. Please try again later.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this booking?')) {
      try {
        await axios.delete(`https://uhospitality-backend-production.up.railway.app/api/deleteBooking/${id}`)
        await fetchBookings()
        alert("Booking deleted successfully")
      } catch (error) {
        console.error('Error deleting booking:', error)
        setError('Failed to delete booking. Please try again.')
      }
    }
  }

  return (
    <div className='sidebar'>
      <SideBar/>
      <div className="booking-container">
        <h1 className="booking-title">Booking Management</h1>
        
        {error && <div className="error-message">{error}</div>}
        
        {isLoading ? (
          <div className="loading-message">Loading bookings...</div>
        ) : bookings.length > 0 ? (
          <div className="booking-table-wrapper">
            <h2 className="booking-table-title">Bookings</h2>
            <table className="booking-table">
              <thead>
                <tr>
                <th>Name</th>
                <th>email</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Tickets</th>
                  <th>Cost</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {bookings.map((booking, index) => (
                  <tr key={booking._id || index}>
                    <td>{booking.name}</td>
                    <td>{booking.email}</td>
                    <td>{booking.date}</td>
                    <td>{booking.time}</td>
                    <td>{booking.tickets}</td>
                    <td>{booking.cost}</td>
                    <td className='actions-btn-booking'>
                      <button onClick={() => handleDelete(booking._id)} className="action-button delete">Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="no-bookings-message">No bookings found.</div>
        )}
      </div>
    </div>
  )
}

export default Booking

