import React, { useEffect } from 'react'
import './about.css'
import img from '../../assets/mountain.jpg'
import img2 from '../../assets/climbing.png'
import img3 from '../../assets/customer2.png'
import video from '../../assets/video.mp4'
import Aos from 'aos'
import 'aos/dist/aos.css'

const About = () => {
  useEffect(()=>{
    Aos.init({duration: 2000})
  },[])

  return (
    <section id='about' className='about section'>
      <div className="seContainer">
        <h3 data-aos = 'fade-left' className="title">
          Why Hiking?
        </h3>

        <div className="mainContent container grid">
          <div data-aos = 'fade-right' data-aos-duration = '2500' className="singleItem">
            <img src={img} alt='About-Image' />
            <h3>1000+ Mountains</h3>
            <p>
             Research shows that a chance to break away from the normal 
             rythms of daily life reduces stress and improve health and well-being.
            </p>
          </div>
          <div data-aos = 'fade-right' data-aos-duration = '2500' className="singleItem">
            <img src={img2} alt='About-Image' />
            <h3>2000+ Hikings</h3>

            <p>
             Research shows that a chance to break away from the normal 
             rythms of daily life reduces stress and improve health and well-being.
            </p>
          </div>
          <div data-aos = 'fade-right' data-aos-duration = '2500' className="singleItem">
            <img src={img3} alt='About-Image' />
            <h3>2000+ Customers</h3>
            <p>
             Research shows that a chance to break away from the normal 
             rythms of daily life reduces stress and improve health and well-being.
            </p>
          </div>
        </div>
        <div className="videoCard container">
          <div className="cardContent grid">
            <div className="cardText">
              <h2>Explore The World</h2>
              <p>Discover the beauty of nature and the thrill of adventure.</p>
            </div>

            <div className="cardVideo">
              <video src={video} autoPlay loop muted type='video/mp4'></video>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default About